import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../layouts/index"
import Header from "../components/header"
import Services from "../components/services"
import Features from "../components/features"
import Pricing from "../components/pricing"
import Partners from "../components/partners"
import SignUp from "../components/signUp"
import Footer from "../components/footer"

import headerMsgs from "../locales/en/header.json"
import serviceMsgs from "../locales/en/services.json"
import featuresMsgs from "../locales/en/features.json"
import pricingMsgs from "../locales/en/pricing.json"
import partnersMsgs from "../locales/en/partners.json"
import signUpMsgs from "../locales/en/signUp.json"
import footerMsgs from "../locales/en/footer.json"

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <html lang="en" />
      </Helmet>
      {/* Nav Bar and Hero */}
      <Header translationMessages={headerMsgs} language="en" />

      {/* Services */}
      <Services translationMessages={serviceMsgs} />

      {/* Services */}
      <Features translationMessages={featuresMsgs} />

      {/* Pricing */}
      <Pricing translationMessages={pricingMsgs} />

      {/* Partners */}
      <Partners translationMessages={partnersMsgs} />

      {/* Sign Up */}
      <SignUp translationMessages={signUpMsgs} />

      {/* Footer */}
      <Footer translationMessages={footerMsgs} />
    </Layout>
  )
}
